<template>
  <div>
    <el-card>
      <span class="h1">パスワード変更</span>
      <el-button type="info" style="float: right;margin-bottom: 20px;" @click="goback">戻る</el-button>
    </el-card>
    <el-card>
      <el-form label-position="right" :model="ruleForm" :rules="rules" ref="ruleForm">
        <el-form-item label="古いパスワードを入力してください" label-width="10%" prop="oldPassword">
          <el-input :type="flag1 ? 'password' : 'text'" v-model="ruleForm.oldPassword" size="medium"
            style="width: 300px">
            <i slot="suffix" :class="[flag1 ? 'el-icon-minus' : 'el-icon-view']" style="margin-top:8px;font-size:18px;"
              autocomplete="auto" @click="flag1 = !flag1" />
          </el-input>

        </el-form-item>
        <el-form-item label="新たなパスワードを入力してください" label-width="10%" prop="newPassword">
          <el-input :type="flag2 ? 'password' : 'text'" v-model="ruleForm.newPassword" size="medium"
            style="width: 300px">
            <i slot="suffix" :class="[flag2 ? 'el-icon-minus' : 'el-icon-view']" style="margin-top:8px;font-size:18px;"
              autocomplete="auto" @click="flag2 = !flag2" />
          </el-input>

        </el-form-item>
        <el-form-item label="新たなパスワードを確認してください" label-width="10%" prop="newPasswords">
          <el-input :type="flag1 ? 'password' : 'text'" v-model="ruleForm.newPasswords" size="medium"
            style="width: 300px">
            <i slot="suffix" :class="[flag3 ? 'el-icon-minus' : 'el-icon-view']" style="margin-top:8px;font-size:18px;"
              autocomplete="auto" @click="flag3 = !flag3" />
          </el-input>

        </el-form-item>
        <el-form-item label-width="8%">
          <el-button type="primary" @click="editPsd('ruleForm')" :disabled="disabled">确认</el-button>
          <el-button @click="canelPsd">取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import md5 from 'js-md5'
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('再度パスワードを入力してください。'));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error('入力したパスワードが一致しません!'));
      } else {
        callback();
      }
    };
    return {
      flag1: true,
      flag2: true,
      flag3: true,
      disabled: false,
      password: "",
      ruleForm: {
        oldPassword: '',
        newPassword: '',
        newPasswords: ''
      },
      rules: {
        oldPassword: [{
          required: true,
          message: '古いパスワードを入力してください',
          trigger: 'blur'
        },
        {
          min: 6,
          max: 15,
          message: 'パスワードは6文字から15文字までです',
          trigger: 'blur'
        }
        ],
        newPassword: [{
          required: true,
          message: '新たなパスワードを入力してください',
          trigger: 'blur'
        },
        {
          min: 6,
          max: 15,
          message: 'パスワードは6文字から15文字までです',
          trigger: 'blur'
        }
        ],
        newPasswords: [{
          required: true,
          validator: validatePass,
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {

    editPsd(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let params = {
            oldPassword: md5(this.ruleForm.oldPassword),
            newPassword: md5(this.ruleForm.newPassword),
          }
          const { data: res } = await this.$http
            .put(this.$url.UpdateMyPassword(params.oldPassword, params.newPassword), params, {
              headers: {
                "Content-Type":
                  "application/json;charset=UTF-8"
              }
            })
          if (res.msgCode == 1) {
            this.$message.success('パスワードの変更が成功です。!')
            window.localStorage.clear()
            this.$router.push("/GetLogin")
          } else {
            this.$message.error(res.message)
          }
        } else {
          return false;
        }
      });
    },
    canelPsd() {
      this.$message.info('パスワードの変更を取消した!')
      this.$router.go(-1)
    },
    goback() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.h1 {
  font-size: 20px;
  font-weight: bold;
}
</style>
